import styled from 'styled-components';
import React from 'react';
import HamburgerMenu from './hamburgerMenu';
import Clock from './clock';

const Style = styled.div`
  img.nav-logo {
    height: 25px;
    width: 25px;
    vertical-align: middle;
  }

  div.header-nav-row-2 {
    display: flex;
  }

  header.header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* padding: 1.2rem 1.5rem; */
    height: 4rem;
    width: 100%;
    z-index: 995;
    overflow: unset;
    position: fixed;
    top: 0;
    background-color: rgb(9, 9, 46);
  }
  nav.header-nav {
    display: flex;
    justify-content: space-between;
  }

  ul.header-nav-list {
    padding: unset;
    height: 100%;
    // width: 100%;
    // position: absolute;
    /* z-index: 1; */
    top: 0;
    right: 0;
    background-color: rgba(73, 87, 165, 0.0226);
    overflow-x: hidden;
    padding: 0;
    margin: 0;
    padding-top: 0.2rem;
  }

  li.header-nav-item {
    display: inline;
    margin-left: 1rem;
    /* margin-top: 1rem; */
    margin-bottom: 1rem;
    overflow: hidden;
  }

  li.header-nav-item-logo {
    margin-left: 1rem;
  }

  label.header-nav-link {
    font-size: 1.4rem;
    font-weight: 400;
    color: white;
    text-decoration: none;
    vertical-align: middle;
  }

  label.header-nav-link:hover {
    color: red;
  }

  span.dot {
    height: 12px;
    width: 12px;
    background-color: orange;
    border-radius: 50%;
    display: inline-block;
    align-self: center;
    margin-left: 7px;

    padding-left: 8px;
  }

  span.dot-connected {
    background-color: green;
  }

  .tooltip-top {
    padding-left: 8px;
  }
  .tooltip-left {
    padding-left: 8px;
  }
  [data-tooltip] {
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    position: relative;
  }
  [data-tooltip]::after {
    background-color: rgba(51, 51, 51, 0.9);
    border-radius: 0.3rem;
    color: #fff;
    content: attr(data-tooltip);
    font-size: 15px;
    font-weight: normal;
    line-height: 1.15rem;
    opacity: 0;
    padding: 0.25rem 0.5rem;
    position: absolute;
    text-align: center;
    text-transform: none;
    transition: opacity 0.2s;
    visibility: hidden;
    z-index: 1;
    white-space: pre-wrap;
    width: 16rem;
    font-size: 0.7rem;
  }
  [data-tooltip].tooltip-right::before {
    border-style: solid;
    border-width: 0.3rem;
    content: '';
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s;
    visibility: hidden;
    border-color: transparent rgba(51, 51, 51, 0.9) transparent transparent;
    top: 50%;
    top: 0.3rem;
    left: calc(110% - 0.3rem);
    margin-top: -0.3rem;
  }
  [data-tooltip].tooltip-right::after {
    top: -0.3rem;
    left: calc(110% + 0.3rem);
    top: 130%;
    /* left: 50%; */
  }

  [data-tooltip]:hover::after,
  [data-tooltip][class*='tooltip-']:hover::before {
    visibility: visible;
    opacity: 1;
  }
`;

export default function Navbar(props) {
  const connectionStatus = props.connected
    ? `Connected
    Last update: ${props.lastUpdate}`
    : 'Connecting';
  return (
    <Style>
      <header className="header">
        <nav className="header-nav">
          <ul className="header-nav-list">
            <li className="header-nav-item header-nav-item-logo">
              <a
                className="no-decoration"
                href="https://ethereummax.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="nav-logo" src="emaxlogo.png" alt="" />
              </a>
            </li>
            <li className="header-nav-item">
              <label className="header-nav-link">maths</label>
            </li>
          </ul>

          {/* <HamburgerMenu></HamburgerMenu> */}
        </nav>
        <div className="header-nav-row-2">
          <span
            className={`tooltip-right dot ${
              props.connected ? 'dot-connected' : ''
            }`}
            data-tooltip={connectionStatus}
            data-html="true"
          ></span>
          <Clock> </Clock>
        </div>
      </header>
    </Style>
  );
}
