import React from 'react';
import styled from 'styled-components';
import TableRow from './tableRow';
import ToolTip from './tooltip';

const TableStyles = styled.div`
  img.img-eth {
    vertical-align: middle;
    width: 15px;
    height: 15px;
    fill: white;
    filter: brightness(0) invert(1);
  }
  padding: 1rem;
  table {
    border-spacing: 0;
    border: 1px solid white;
    font-size: 14px;
    color: white;

    margin-left: auto;
    margin-right: auto;
    margin: auto;

    thead {
      position: sticky;
      /* top: 0; */
      z-index: 9;
      top: 4rem;
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    tr.expanded {
    }
    tr.odd {
      /* background-color: rgb(44, 34, 64); */
    }
    tr.even {
      /* background-color: rgb(248, 212, 210, 0.4); */
      background-color: rgba(236, 29, 50, 0.15);
      //353238
      //322d3d
    }
    tr.current-price {
      /* background-color: green; */
      background-color: rgba(236, 29, 50, 0.7);
      /* background-color: #8175ad; */
      /* font-weight: bold; */
    }
    tr.results {
      cursor: pointer;
    }
    tr.expanded > td {
      box-shadow: inset 0 3px 6px -3px rgba(0, 0, 0, 0.2);
      padding: 0;
    }

    tr.expanded > td > .inner {
      margin-left: 10px;
      margin-top: 0px;
      margin-bottom: 10px;
      overflow: hidden;
    }
    th {
      /* background-color: #e87670; */
      background-color: rgba(236, 29, 50, 0.9);
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid white;
      border-right: 1px solid white;
      :last-child {
        border-right: 0;
      }
    }
    td.td-expand {
      text-align: center;
    }
    tr {
      :not(.current-price) {
        td.td-price {
          padding-right: 20px;
        }
      }
    }
    .underline {
      text-decoration: underline;
    }
  }
  .table-container {
    margin: 10px;
    padding: 0;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
  }

  @media only screen and (max-width: 700px) {
    tr {
      th.mobile-hide,
      td.mobile-hide {
        display: none;
      }
    }
  }
`;

export default function Table({ columns, data }) {
  const isLoading = data === null;
  return (
    <TableStyles>
      <table>
        <thead>
          <tr>
            {columns
              // .filter((x) => x.show)
              .map((column) => (
                <th
                  className={!column.show ? 'mobile-hide' : ''}
                  key={`th-${column.accessor}`}
                >
                  {/* {column.prefix ? column.prefix : ''} */}
                  {column.Header}
                  {column.tooltip ? (
                    <ToolTip
                      image="question-mark-inside-a-circle-svgrepo-com.svg"
                      text={column.tooltip}
                    />
                  ) : (
                    ''
                  )}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={6}>
                <em>Loading...</em>
              </td>
            </tr>
          ) : (
            data.map((row, index) => (
              <TableRow key={index} index={index} row={row} columns={columns} />
            ))
          )}
        </tbody>
      </table>
    </TableStyles>
  );
}
