import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import MillionLabel from '../millionLabel';
import Results from '../results';
import Slider from '../slider';
import { toNumbersOnly, numberWithCommas } from '../../utils/dataUtil';
import { setCookie, getCookie } from '../../utils/cookieUtil';
import StyledLabel from '../inputs/styledLabel';
import StyledTextarea from '../inputs/styledTextarea';
import { validWallet } from '../../utils/walletUtils';
import { useCancellablePromise } from '../../helpers/useCancellablePromise';
import { loadWalletInfo } from '../../utils/web3Util';
import Spinner from '../spinner';
import contracts from '../../utils/contracts';

const Styles = styled.div`
  div.input-container {
    margin-left: auto;
    margin-right: auto;
  }
  .display-block {
    display: block;
  }
  .display-none {
    display: none;
  }
  .emax-input {
    font-weight: bold;
    font-size: 22px;
  }

  div.input-wallet-green textarea {
    background-color: #e5fbe5;
  }

  div.input-wallet-red textarea {
    background-color: #ffdbdb;
  }

  div.styled-textarea {
    margin-bottom: 20px;
  }

  div.styled-label {
    margin-bottom: 5px;
  }

  div.invalid-wallet {
    color: red;
    text-align: center;
    font-size: 1.3rem;
  }
`;

export default function ValueCalculator(props) {
  const [walletAddress, setWalletAddress] = React.useState(
    localStorage.getItem('walletAddress') ?? ''
  );
  const [walletInfo, setWalletInfo] = useState({});

  const showHideClassName = 'display-block';

  const walletValidionHelper = (() => {
    if (validWallet(walletAddress)) {
      return 'input-wallet-green';
    } else if (walletAddress.length === 42) {
      return 'input-wallet-red';
    } else return '';
  })();

  function handleWalletChange(evt) {
    const { value } = evt.target;
    const sanitisedVal = value.replace(/\n/, '').toLowerCase();

    if (value.length <= 42 && sanitisedVal !== walletAddress) {
      localStorage.setItem('walletAddress', sanitisedVal);
      setWalletAddress(sanitisedVal);
      setupLoadWalletInterval(sanitisedVal);
    }
  }

  const { cancellablePromise } = useCancellablePromise();
  let walletInterval = useRef(false);

  React.useEffect(() => {
    setupLoadWalletInterval(walletAddress);

    return function cleanup() {
      clearInterval(walletInterval.current);
    };
  }, []);

  const stopAndClearWalletInfoInterval = () => {
    clearInterval(walletInterval.current);
    setWalletInfo({});
  };

  const setupLoadWalletInterval = (val) => {
    if (validWallet(val)) {
      stopAndClearWalletInfoInterval();
      loadWallet(val);
      walletInterval.current = setInterval(() => loadWallet(val), 10000);
    } else {
      stopAndClearWalletInfoInterval();
    }
  };

  const loadWallet = async (val) => {
    try {
      const data = await cancellablePromise(loadWalletInfo(val));
      setWalletInfo(data);
      console.log(data);
    } catch (e) {
      if (e.isCanceled) {
        console.log('Promise was canceled');
      } else {
        console.log(e);
      }
    }
  };

  const priceL1 = parseFloat(
    props.priceData.tokens[contracts.contractL1].usdPrice
  );
  const priceL2 = parseFloat(
    props.priceData.tokens[contracts.contractL2].usdPrice
  );
  const ethPriceL1 = parseFloat(
    props.priceData.tokens[contracts.contractL1].ethPrice
  );
  const ethPriceL2 = parseFloat(
    props.priceData.tokens[contracts.contractL2].ethPrice
  );

  const l1Value =
    walletInfo.ethBalance <= 0
      ? '0'
      : (walletInfo.ethBalance * priceL1).toFixed(2);

  const l2Value =
    walletInfo.arbBalance <= 0
      ? '0'
      : (walletInfo.arbBalance * priceL2).toFixed(2);

  const totalValue = parseFloat(l1Value) + parseFloat(l2Value);

  // const currentValue = roundFloatToString(
  //   props.noEmax * roundFloat(priceL2, 10),
  //   2
  // );
  const l1EthValue = ((walletInfo.ethBalance * priceL1) / ethPriceL1).toFixed(
    3
  );
  const l2EthValue = ((walletInfo.arbBalance * priceL2) / ethPriceL2).toFixed(
    3
  );

  return (
    <>
      <Styles>
        <div className={showHideClassName}>
          <div className="input-container">
            <StyledTextarea
              key="textarea-wallet-address"
              label="Wallet Address"
              name="textarea-wallet-address"
              height={60}
              value={walletAddress}
              className={`styled-textarea ${walletValidionHelper}`}
              onChange={handleWalletChange}
            />
            {validWallet(walletAddress) &&
              props.priceData &&
              walletInfo.loaded &&
              walletInfo.valid && (
                <StyledLabel
                  key="label-l1-balance"
                  label="L1 Balance"
                  name="label-l1-balance"
                  height={35}
                  value={`${numberWithCommas(parseInt(walletInfo.ethBalance))}`}
                  className="styled-label"
                />
              )}
            {validWallet(walletAddress) &&
              props.priceData &&
              walletInfo.loaded &&
              walletInfo.valid && (
                <StyledLabel
                  key="label-l2-balanace"
                  label="L2 Balance"
                  name="label-l2-balanace"
                  height={35}
                  value={`${numberWithCommas(parseInt(walletInfo.arbBalance))}`}
                  className="styled-label"
                />
              )}
            {validWallet(walletAddress) &&
              props.priceData &&
              walletInfo.loaded &&
              walletInfo.valid && (
                <StyledLabel
                  key="label-total-balance"
                  label="Total Balance"
                  name="label-total-balance"
                  height={55}
                  value={`${numberWithCommas(
                    parseInt(walletInfo.ethBalance) +
                      parseInt(walletInfo.arbBalance)
                  )}
                  \n
                  ($${(
                    (parseInt(walletInfo.ethBalance) +
                      parseInt(walletInfo.arbBalance)) *
                    priceL2
                  ).toFixed(2)})`}
                  className="styled-label"
                />
              )}

            {validWallet(walletAddress) && !walletInfo.loaded && <Spinner />}
            {validWallet(walletAddress) &&
              walletInfo.loaded &&
              !walletInfo.valid && (
                <div className="invalid-wallet">Wallet not found!</div>
              )}
          </div>

          {/* <MillionLabel
            noEmax={
              parseInt(walletInfo.ethContract) +
              parseInt(walletInfo.arbContract)
            }
            priceData={props.priceData}
          /> */}

          <Results
            noEmax={
              parseInt(walletInfo.ethBalance) + parseInt(walletInfo.arbBalance)
            }
            priceData={props.priceData}
          />
        </div>
      </Styles>
    </>
  );
}
