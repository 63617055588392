import React, { useState } from 'react';
import styled from 'styled-components';
import RadioButtons from '../inputs/radioButtons';
import UseInputResults from './useInputResults';
import UseWalletResults from './useWalletResults';
import InfoModal from '../infoModal';

const Style = styled.div`
  .radio-wrapper {
    margin-bottom: 20px;
  }
`;

export default function ModeRouter(props) {
  const [activeTab, setActiveTab] = React.useState(
    localStorage.getItem('activeTab') ?? 'USE_INPUT'
  );
  const [showWarningModal, setShowWarningModal] = useState(false);

  return (
    <>
      <InfoModal
        isOpen={showWarningModal}
        setIsOpen={(val) => setShowWarningModal(val)}
      />
      <Style>
        <RadioButtons
          activeTab={activeTab}
          setActiveTab={(evt) => {
            const { value } = evt.target;

            if (value === 'USE_WALLET' && !localStorage.getItem('warned')) {
              localStorage.setItem('warned', true);
              setShowWarningModal(true);
            }

            localStorage.setItem('activeTab', value);
            setActiveTab(value);
          }}
        />
        {/* <hr /> */}
        {activeTab === 'USE_INPUT' && props.priceData.tokens && (
          <UseInputResults priceData={props.priceData} />
        )}
        {activeTab === 'USE_WALLET' && props.priceData.tokens && (
          <UseWalletResults priceData={props.priceData} />
        )}
      </Style>
    </>
  );
}
