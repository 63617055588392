import React, { useState, useEffect, useRef } from 'react';
import HeaderInfo from './components/headerInfo';
import ModeRouter from './components/blocks/modeRouter';
import './App.css';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import Navbar from './components/navbar';
import Clock from './components/clock';
import { useCancellablePromise } from './helpers/useCancellablePromise';
// import { loadWalletInfo } from './utils/web3Util';

const wsUrl = 'wss://api.emax.tools';
// const wsUrl = 'ws://localhost:3010/price';

let client = new W3CWebSocket(wsUrl);

function App() {
  const [priceData, setPriceData] = useState({});
  const [ws, setWs] = useState(null);
  const [connected, setConnected] = useState(false);

  const openEventListener = () => {
    setWs(client);
    setConnected(true);
  };

  const incomingMessageListener = (message) => {
    console.log(message.data);
    console.log(client.readyState);
    let messageData = JSON.parse(message.data);
    setPriceData(messageData);
  };

  const closeSocket = (event) => {
    console.log('disconnected');
    setConnected(false);
    setTimeout(() => {
      console.log('retrying connection');
      client = new W3CWebSocket(wsUrl);
      client.onopen = openEventListener;
      client.onmessage = incomingMessageListener;
      client.onclose = closeSocket;
      client.onerror = errorSocker;
    }, 1500);
  };

  const errorSocker = (event) => {
    console.log('error');
    // setConnected(false);
    // setTimeout(() => {
    //   console.log('retrying connection');
    //   client = new W3CWebSocket(wsUrl);
    //   client.onopen = openEventListener;
    //   client.onmessage = incomingMessageListener;
    //   client.onclose = closeSocket;
    //   client.onerror = errorSocker;
    // }, 3000);
  };

  useEffect(() => {
    client.onopen = openEventListener;
    client.onmessage = incomingMessageListener;
    client.onclose = closeSocket;
    client.onerror = errorSocker;
    console.log('app mounted');

    return function cleanup() {
      client.close();
    };
  }, []);

  return (
    <>
      <Navbar
        connected={connected}
        lastUpdate={priceData.lastUpdated ?? 'waiting'}
      />

      <div className="wrapper">
        <main>
          <img height="80px" width="80px" src="emaxlogo.png" alt="" />
          <b className="community-header">[ community edition ]</b>
          {/* <Clock></Clock> */}

          <HeaderInfo priceData={priceData} />

          <h1 className="title">
            <span className="emax-title">eMax </span> <span>maths</span>
          </h1>

          <ModeRouter priceData={priceData} />
        </main>

        <footer className="footer">
          <div className="row">
            {/* <span className="column">
              <a
                href="https://ethereummax.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                Powered by eMax
              </a>
            </span> */}
            {/* <span className="column">
              <a
                href="https://t.me/thipsssss"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact Me
                <img className="telegram" src="telegram.svg" alt="telegram" />
              </a>
            </span> */}
            <span className="column">
              <a
                href="https://t.me/emaxprices"
                target="_blank"
                rel="noopener noreferrer"
              >
                New! - eMax Price Updates
                <img className="telegram" src="telegram.svg" alt="telegram" />
              </a>
            </span>
          </div>
          {/* <div className="disclaimer">
            <label>
              Disclaimer: emax.tools is a community tool, built and maintained
              by a community member. All information provided on the site is for
              informational purposes only and is not intended to be a substitute
              for financial advice. For feedback use the 'Contact Me' link.
            </label>
          </div> */}
        </footer>
      </div>
    </>
  );
}

export default App;
