import React, { useState, useEffect, useRef, Suspense } from 'react';

import styled from 'styled-components';

const RadioButtonStyles = styled.div`
  margin-top: 15px;
  .wrapper {
    display: inline-flex;
    background: inherit;
    height: 75px;
    width: 350px;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 5px;
    border-color: white;
    padding: 10px 15px 20px 15px;
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.2);
    flex-direction: row;

    display: flex;

    margin-left: auto;
    margin-right: auto;
    margin: auto;
  }
  .wrapper .option {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 10px;
    border-radius: 5px;
    cursor: pointer;
    padding: 0 10px;
    border: 2px solid lightgrey;
    transition: all 0.3s ease;
  }
  .wrapper .option .dot {
    opacity: 0;
    position: fixed;
    width: 0;
  }

  .wrapper .option .dot::before {
    position: absolute;
    content: '';
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    background: #0069d9;
    /* background: rgb(236, 29, 50) */
    border-radius: 50%;
    opacity: 0;
    transform: scale(1.5);
    transition: all 0.3s ease;
  }
  input[type='radio'] {
    display: none;
  }

  ::selection {
    color: white;
    background-color: black;
  }
  ::-moz-selection {
    color: white;
    background-color: black;
  }
  mark {
    color: white;
    background-color: black;
  }
  [type='checkbox']:checked,
  [type='checkbox']:not(:checked),
  [type='radio']:checked,
  [type='radio']:not(:checked) {
    position: absolute;
    left: -9999px;
    width: 0;
    height: 0;
    visibility: hidden;
  }

  .checkbox-tools + label {
    /* border-color: rgb(144, 179, 249); */
    border-color: rgb(236, 29, 50);
    border-width: 1px;
    border-style: solid;
  }

  .checkbox-tools:checked + label,
  .checkbox-tools:not(:checked) + label {
    position: relative;
    display: inline-block;
    padding: 10px;
    width: 110px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
    margin: 0 auto;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 0px;
    text-align: center;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    text-transform: uppercase;
    color: white;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
    font-weight: bold;
  }
  .checkbox-tools:not(:checked) + label {
    background-color: transparent;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
  .checkbox-tools:checked + label {
    /* background-color: rgb(144, 179, 249); */
    background-color: rgb(236, 29, 50);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  .checkbox-tools:not(:checked) + label:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  .checkbox-tools:checked + label::before,
  .checkbox-tools:not(:checked) + label::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    /* background-image: linear-gradient(298deg, red, yellow); */
    z-index: -1;
  }
  .checkbox-tools:checked + label .uil,
  .checkbox-tools:not(:checked) + label .uil {
    font-size: 24px;
    line-height: 24px;
    display: block;
    padding-bottom: 10px;
  }

  .for-checkbox-tools {
    font-style: italic;
  }

  img.select-icon {
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(336deg)
      brightness(102%) contrast(105%);
  }

  /* @media only screen and (max-width: 700px) {
    div.wrapper {
      display: flex;
    }
  } */
`;

export default function RadioButtons(props) {
  return (
    <RadioButtonStyles>
      <div className="wrapper radio-wrapper">
        <input
          className="checkbox-tools"
          type="radio"
          name="modeSelect"
          id="useInput"
          value="USE_INPUT"
          onChange={props.setActiveTab}
          checked={props.activeTab == 'USE_INPUT'}
        />
        <label htmlFor="useInput" className="for-checkbox-tools option-11">
          <div className="dot" />
          <img
            className="select-icon"
            height="20px"
            width="20px"
            src="calc.svg"
            alt=""
          />
          <div>
            Use <br />
            Input
          </div>
        </label>
        <input
          className="checkbox-tools"
          type="radio"
          name="modeSelect"
          id="useWallet"
          value="USE_WALLET"
          onChange={props.setActiveTab}
          checked={props.activeTab == 'USE_WALLET'}
        />
        <label htmlFor="useWallet" className="for-checkbox-tools option-12">
          <div className="dot" />
          <img
            className="select-icon"
            height="20px"
            width="20px"
            src="wallet.svg"
            alt=""
          />
          <div>
            Use
            <br />
            Wallet
          </div>
        </label>
      </div>
    </RadioButtonStyles>
  );
}
