import React from 'react';
import styled from 'styled-components';

const CheckBoxStyles = styled.div`
  .centre {
    vertical-align: middle;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 30px; //50
    height: 20px; //30
  }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .slider:before {
    position: absolute;
    content: '';
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  input:checked + .slider {
    background-color: rgb(236, 29, 50);
    /* background-color: #2196f3; */
  }
  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px);
  }
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  .slider.round:before {
    border-radius: 50%;
  }

  label {
    color: white;
    font-size: 13px;
    padding-left: 5px;
  }
  div.slider-container {
    text-align: right;
    font-size: 13px;
  }
`;

export default function Slider(props) {
  return (
    <CheckBoxStyles>
      <div className="slider-container">
        <label className="switch">
          <input
            type="checkbox"
            defaultChecked={props.defaultChecked}
            onChange={props.onChange}
          />
          <span className="slider round" />
        </label>
        <label className="centre">Save</label>
      </div>
    </CheckBoxStyles>
  );
}
