import styled from 'styled-components';
import React from 'react';

const Style = styled.div`
  .hamburger {
    display: block;
    cursor: pointer;
    z-index: 1000;
    /* height: 35px;
    width: 35px; */
    /* margin-top: 30px; */
    /* height: 100%;
    width: 100%; */

    /* border-style: solid;
    border-color: white; */
    margin-top: 12px;
    margin-right: 12px;
    height: 30px;
    width: 30px;
    position: relative;
  }
  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }
  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  /* div.hamburger {
    text-align: right;
    margin-top: 15px;
    margin-right: 15px;

    span {
      display: block;
      font-size: 0.8rem;
    }
  } */

  .bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: white;
  }

  .nav-menu.active {
    left: 0;
    width: 100%;
    background-color: rgba(9, 9, 46, 0.9);
  }

  .nav-menu {
    padding: unset;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(73, 87, 165, 0.226);
    overflow-x: hidden;
    padding: 0;
    margin: 0;
    padding-top: 0.2rem;
  }

  .nav-menu {
    display: flex;
    align-items: center;
    position: fixed;
    left: -100%;
    flex-direction: column;

    border-radius: 10px;
    text-align: center;
    box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
    transition: none;
    padding: 0;
    margin: 0;

    /* -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; */
  }

  .nav-item {
    display: inline;
    margin-left: 3rem;
    margin-bottom: 1rem;
    overflow: hidden;
    /* padding: 8px 8px 8px 32px; */
  }
  .nav-item {
    margin-left: 0rem;
    margin-bottom: 2rem;
    /* padding: 8px 8px 8px 32px; */
  }
`;

// className={`hamburger ${props.menuActive ? ' active' : ''}`}

export default function HamburgerMenu() {
  const [menuActive, setMenuActive] = React.useState(false);

  let scrollPosition = 0;

  function enable() {
    scrollPosition = window.pageYOffset;
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';
    document.body.style.top = `-${scrollPosition}px`;
    document.body.style.width = '100%';
  }
  function disable() {
    document.body.style.removeProperty('overflow');
    document.body.style.removeProperty('position');
    document.body.style.removeProperty('top');
    document.body.style.removeProperty('width');
    window.scrollTo(0, scrollPosition);
  }

  const toggle = (e) => {
    const updatedState = !menuActive;
    if (updatedState) {
      enable();
    } else {
      // document.body.style.overflow = 'unset';
      disable();
    }
    setMenuActive(updatedState);
  };

  return (
    <Style>
      {/* <div className="menu-options-container"> */}
      <ul className={`nav-menu${menuActive ? ' active' : ''}`}>
        <li className="nav-item">
          <a href="#" className="nav-link" type="maths" onClick={toggle}>
            Maths
          </a>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link" type="charts" onClick={toggle}>
            Charts
          </a>
        </li>
      </ul>
      {/* </div> */}
      {/* <div className="nav-container"> */}
      <div
        className={`hamburger ${menuActive ? ' active' : ''}`}
        onClick={toggle}
      >
        <span className="bar" />
        <span className="bar" />
        <span className="bar" />
      </div>
      {/* </div> */}
    </Style>
  );
}
