import React, { useState, useRef } from 'react';
import styled from 'styled-components';

const Styles = styled.div`
  .darkBG {
    background-color: rgba(0, 0, 0, 0.7);
    width: 100vw;
    height: 100vh;
    z-index: 997;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
  }

  .centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 998;
  }

  .modal {
    width: 250px;
    /* height: 170px; */
    background: white;
    color: white;
    z-index: 999;
    border-radius: 16px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  }

  .modalHeader {
    height: 50px;
    background: white;
    overflow: hidden;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .heading {
    margin: 0;
    padding: 10px;
    color: #2c3e50;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
  }

  .modalContent {
    padding: 10px;
    font-size: 14px;
    color: #2c3e50;
    text-align: center;
  }

  .modalActions {
    /* position: absolute; */
    bottom: 2px;
    margin-bottom: 10px;
    width: 100%;
  }

  .actionsContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding: 10px;
  }

  .closeBtn {
    cursor: pointer;
    font-weight: 500;
    padding: 4px 8px;
    border-radius: 8px;
    border: none;
    font-size: 18px;
    color: #2c3e50;
    background: white;
    transition: all 0.25s ease;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
    position: absolute;
    right: 0;
    top: 0;
    align-self: flex-end;
    margin-top: -7px;
    margin-right: -7px;
  }

  .closeBtn:hover {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
    transform: translate(-4px, 4px);
  }

  .deleteBtn {
    margin-top: 10px;
    cursor: pointer;
    font-weight: 500;
    padding: 11px 28px;
    border-radius: 12px;
    font-size: 0.8rem;
    border: none;
    color: #fff;
    background: #ff3e4e;
    transition: all 0.25s ease;
  }

  .deleteBtn:hover {
    box-shadow: 0 10px 20px -10px rgba(255, 62, 78, 0.6);
    transform: translateY(-5px);
    background: #ff3e4e;
  }

  .cancelBtn {
    width: 90%;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    font-weight: 500;
    padding: 11px 28px;
    border-radius: 12px;
    font-size: 1rem;
    border: none;
    color: #2c3e50;
    background: #fcfcfc;
    transition: all 0.25s ease;
  }

  .cancelBtn:hover {
    box-shadow: none;
    transform: none;
    /* background: #d7d7d7; */
    background: rgba(144, 179, 249, 0.5);
  }
`;

export default function InfoModal(props) {
  let scrollPosition = useRef(0);
  let enabled = useRef(false);

  function enable() {
    enabled.current = true;
    scrollPosition.current = window.pageYOffset;
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';
    document.body.style.top = `-${scrollPosition.current}px`;
    document.body.style.width = '100%';
    document
      .querySelector('meta[name="theme-color"]')
      .setAttribute('content', 'rgba(0, 0, 0, 0.07)');
  }
  function disable() {
    enabled.current = false;
    document.body.style.removeProperty('overflow');
    document.body.style.removeProperty('position');
    document.body.style.removeProperty('top');
    document.body.style.removeProperty('width');
    window.scrollTo(0, scrollPosition.current);
    document
      .querySelector('meta[name="theme-color"]')
      .setAttribute('content', 'rgb(9, 9, 46)');
  }

  const toggle = () => {
    const updatedState = !props.isOpen;
    if (updatedState) {
      enable();
    } else {
      disable();
    }
    props.setIsOpen(updatedState);
  };

  if (!enabled.current && props.isOpen) {
    enable();
  }

  return (
    <Styles>
      {props.isOpen && (
        <>
          <div className="darkBG" onClick={() => toggle()} />
          <div className="centered">
            <div className="modal">
              <div className="modalHeader">
                <h5 className="heading">Wallet Address</h5>
              </div>
              {/* <button className="closeBtn" onClick={() => setIsOpen(false)}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button> */}
              <div className="modalContent">
                This is your PUBLIC wallet address.
              </div>
              <div className="modalContent">
                Reminder to NEVER enter your seedphrase anywhere.
              </div>
              <div className="modalActions">
                <div className="actionsContainer">
                  <button className="deleteBtn" onClick={() => toggle()}>
                    OK
                  </button>
                  {/* <button className="cancelBtn" onClick={() => toggle()}>
                    OK
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Styles>
  );
}
