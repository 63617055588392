import React from 'react';

export default function TableRow(props) {
  const [state, setState] = React.useState({
    expanded: false,
  });

  const toggleExpander = (e) => {
    setState((state) => ({
      expanded: !state.expanded,
    }));
  };

  const { row, columns, index } = props;
  const rowType = index % 2 === 0 ? 'even' : 'odd';
  const expandClass = state.expanded ? ' expanded' : '';
  const rowClass = row.class ? ` ${row.class}` : '';
  return (
    <>
      <tr
        className={`results ${rowType}${expandClass}${rowClass}`}
        onClick={toggleExpander}
        key={`${index}-val`}
      >
        {state.expanded && (
          <td colSpan={6} key={`${index}-meta`}>
            <div className="inner">
              <h3 className="underline">{row.price}</h3>
              <p>zeros: {row.zeros}</p>
              <p>value: {row.value}</p>
              <p>ETH value: {row.ethValue}</p>
              <p>market cap: {row.marketCap}</p>
              <p>multiplier: {row.multiplier}</p>
            </div>
          </td>
        )}
        {!state.expanded &&
          columns
            // .filter((column) => column.show)
            .map((column) => (
              <td
                className={
                  `${
                    (!column.show ? 'mobile-hide' : '') +
                    (column.accessor === 'price' ? 'underline' : '')
                  } td-${column.accessor}` + ` mono`
                }
                key={`${index}-${column.accessor}`}
              >
                {row[column.accessor]}
              </td>
            ))}
      </tr>
    </>
  );
}
