import Web3 from 'web3/dist/web3.min';

let ethToken = '0x15874d65e649880c2614e7a480cb7c9a55787ff6';
const ethURL = 'https://rpc.ankr.com/eth';
const ethWeb3 = new Web3(ethURL);

let arbToken = '0x123389c2f0e9194d9ba98c21e63c375b67614108';
const arbURL = 'https://arb1.arbitrum.io/rpc';
const arbWeb3 = new Web3(arbURL);

const decimals = 18;

function secondsToDhms(seconds) {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24));
  var h = Math.floor((seconds % (3600 * 24)) / 3600);
  var m = Math.floor((seconds % 3600) / 60);
  var s = Math.floor(seconds % 60);

  var dDisplay = d > 0 ? d + (d == 1 ? ' day, ' : ' days, ') : '';
  var hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : '';
  var mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' minutes, ') : '';
  var sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';
  return dDisplay + hDisplay + mDisplay + sDisplay;
}

const minABI = [
  // balanceOf
  {
    constant: true,
    inputs: [{ name: '_owner', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: 'balance', type: 'uint256' }],
    type: 'function',
  },
  // decimals
  {
    constant: true,
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', type: 'uint8' }],
    type: 'function',
  },
];

const arbABI = minABI.concat(
  // _totalSupply
  {
    constant: true,
    inputs: [],
    name: '_totalSupply',
    outputs: [{ name: '', type: 'uint256' }],
    type: 'function',
  }
);

const ethABI = minABI.concat(
  // _totalSupply
  {
    constant: true,
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', type: 'uint256' }],
    type: 'function',
  }
);

const ethContract = new ethWeb3.eth.Contract(ethABI, ethToken);
const arbContract = new arbWeb3.eth.Contract(arbABI, arbToken);

export const loadWalletInfo = async (account) => {
  // const account = '0xdc5ef575598dbe53420308a3bb06fa6d32f02c5c';

  let ethBalance = await ethContract.methods.balanceOf(account).call();
  let arbBalance = await arbContract.methods.balanceOf(account).call();
  return {
    loaded: true,
    valid: ethBalance > 0 || arbBalance > 0,
    ethBalance: ethBalance / 10 ** 18,
    arbBalance: arbBalance / 10 ** 18,
    timestamp: new Date(),
  };
};

export const loadContractSupply = async () => {
  let ethSupply = await ethContract.methods.totalSupply().call();
  let arbSupply = await arbContract.methods._totalSupply().call();
  return {
    loaded: true,
    valid: ethSupply > 0 || arbSupply > 0,
    ethSupply: ethSupply / 10 ** 18,
    arbSupply: arbSupply / 10 ** 18,
    timestamp: new Date(),
  };
};
