import React from 'react';
import styled from 'styled-components';

const ToolTipStyles = styled.span`
  .info {
    width: 12px;
    height: 12px;
    fill: white;
    filter: brightness(0) invert(1);
  }
  .tooltip-top {
    padding-left: 8px;
  }
  .tooltip-left {
    padding-left: 8px;
  }
  [data-tooltip] {
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    position: relative;
  }
  [data-tooltip]::after {
    background-color: rgba(51, 51, 51, 0.9);
    border-radius: 0.3rem;
    color: #fff;
    content: attr(data-tooltip);
    font-size: 15px;
    font-weight: normal;
    line-height: 1.15rem;
    opacity: 0;
    padding: 0.25rem 0.5rem;
    position: absolute;
    text-align: center;
    text-transform: none;
    transition: opacity 0.2s;
    visibility: hidden;
    z-index: 1;
    white-space: pre-wrap;
    width: 16rem;
  }
  [data-tooltip].tooltip-top::before {
    border-style: solid;
    border-width: 0.3rem;
    content: '';
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s;
    visibility: hidden;
    border-color: rgba(51, 51, 51, 0.9) transparent transparent transparent;
    top: 0;
    left: 50%;
    margin-left: -0.3rem;
  }
  [data-tooltip].tooltip-top::after {
    width: 20rem;
    font-size: 13px;
    bottom: 100%;
    left: 50%;
    transform: translate(-70%);
    /* transform: translate(-50%); */
  }
  [data-tooltip].tooltip-bottom::before {
    border-style: solid;
    border-width: 0.3rem;
    content: '';
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s;
    visibility: hidden;
    border-color: transparent transparent rgba(51, 51, 51, 0.9) transparent;
    bottom: 0;
    left: 50%;
    margin-left: -0.3rem;
  }
  [data-tooltip].tooltip-bottom::after {
    top: 100%;
    left: 50%;
    transform: translate(-50%);
  }
  [data-tooltip].tooltip-left::before {
    border-style: solid;
    border-width: 0.3rem;
    content: '';
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s;
    visibility: hidden;
    border-color: transparent transparent transparent rgba(51, 51, 51, 0.9);
    top: 0.3rem;
    right: calc(110% - 0.3rem);
    margin-top: -0.3rem;
  }
  [data-tooltip].tooltip-left::after {
    top: -0.3rem;
    right: calc(110% + 0.3rem);
  }
  [data-tooltip].tooltip-right::before {
    border-style: solid;
    border-width: 0.3rem;
    content: '';
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s;
    visibility: hidden;
    border-color: transparent rgba(51, 51, 51, 0.9) transparent transparent;
    top: 50%;
    top: 0.3rem;
    left: calc(110% - 0.3rem);
    margin-top: -0.3rem;
  }
  [data-tooltip].tooltip-right::after {
    top: -0.3rem;
    left: calc(110% + 0.3rem);
  }

  @media only screen and (max-width: 700px) {
    [data-tooltip].tooltip-mobile::before {
      display: none;
    }
    [data-tooltip].tooltip-mobile:after {
      font-size: 1rem;
      width: 18rem;
      position: fixed;
      bottom: auto;
      top: 50%;
      left: 50%;
      text-align: center;
      transform: translate(-50%);
      white-space: pre-wrap;
    }
    [data-tooltip].tooltip-top:after {
      width: 17rem;
      font-size: 11px;
    }
    [data-tooltip].tooltip-left:after {
      width: 14rem;
      font-size: 13px;
    }
  }
  [data-tooltip]:hover::after,
  [data-tooltip][class*='tooltip-']:hover::before {
    visibility: visible;
    opacity: 1;
  }
`;

export default function Tooltip(props) {
  const { image } = props;
  const position = props.position ? props.position : 'top';

  return (
    <ToolTipStyles>
      <span
        className={`tooltip-${position}`}
        data-tooltip={props.text}
        data-html="true"
      >
        <span focusable="false" aria-hidden="true">
          <img className="info" src={image} alt="i" />
        </span>
      </span>
    </ToolTipStyles>
  );
}
