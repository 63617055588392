import { roundFloat, roundFloatToString } from './mathUtil';

const truncateDecimals = function (number, digits) {
  const multiplier = Math.pow(10, digits);
  const adjustedNum = number * multiplier;
  const truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);

  return truncatedNum / multiplier;
};

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const getFirstNonZeroDecimalIdx = (str) =>
  /[1-9]/.exec(roundFloatToString(str, 10).split('.')[1]).index;

const getIncrementVal = (decimalIdxToIncrement) =>
  1 / (1000 * Math.pow(10, decimalIdxToIncrement - 2));

export function toNumbersOnly(str) {
  return str ? str.replace(/\D/g, '') : '';
}

export function createIndex(
  data,
  start,
  maxDecToDisplay,
  tokenCount,
  unburnedSupply,
  curPrice,
  ethPrice
) {
  const firstNonZeroDecimal = getFirstNonZeroDecimalIdx(start);
  const increment = getIncrementVal(firstNonZeroDecimal + 1);
  const stop = increment * 100;

  for (
    let i = start;
    roundFloat(i, 10) < roundFloat(stop, 10);
    i += increment
  ) {
    const nextprice = roundFloatToString(i + increment, maxDecToDisplay);
    const curPriceFixed = roundFloatToString(curPrice, maxDecToDisplay + 1);
    const rowPrice = roundFloatToString(i, maxDecToDisplay);
    data.push({
      class:
        parseFloat(curPriceFixed) === parseFloat(rowPrice)
          ? 'current-price'
          : '',
      price: `$${rowPrice}`,
      value: `$${numberWithCommas(roundFloat(rowPrice * tokenCount))}`,
      marketCap: `$${numberWithCommas(roundFloat(rowPrice * unburnedSupply))}`,
      multiplier: `${numberWithCommas(roundFloatToString(i / curPrice, 2))}x`,
      zeros: getFirstNonZeroDecimalIdx(i),
      rawPrice: i,
      ethValue: numberWithCommas(
        roundFloatToString((rowPrice * tokenCount) / ethPrice, 3)
      ),
    });
    if (
      parseFloat(curPriceFixed) > parseFloat(rowPrice) &&
      parseFloat(curPriceFixed) < parseFloat(nextprice)
    ) {
      data.push({
        class: 'current-price',
        price: `$${curPriceFixed}`,
        value: `$${numberWithCommas(roundFloat(curPriceFixed * tokenCount))}`,
        marketCap: `$${numberWithCommas(
          roundFloat(curPriceFixed * unburnedSupply)
        )}`,
        multiplier: `${numberWithCommas(
          roundFloatToString(curPriceFixed / curPrice, 2)
        )}x`,
        zeros: getFirstNonZeroDecimalIdx(curPrice),
        rawPrice: curPrice,
        ethValue: numberWithCommas(
          roundFloatToString((curPrice * tokenCount) / ethPrice, 3)
        ),
      });
    }

    if (start >= 0.01) {
      return data;
    }
  }
  return createIndex(
    data,
    stop,
    maxDecToDisplay,
    tokenCount,
    unburnedSupply,
    curPrice,
    ethPrice
  );
}

export function createData(curPrice, tokenCount, unburnedSupply, ethPrice) {
  const firstNonZeroDecimal = getFirstNonZeroDecimalIdx(curPrice); // get first non 0 decimal val

  const overallMaxDecimals = firstNonZeroDecimal + 2; // i.e. 0.000000077 = 9

  const minDecimals = firstNonZeroDecimal + 1; // i.e. 0.00000007 = 8
  const startLoop = truncateDecimals(curPrice, minDecimals); // i.e. 0.00000007

  const data = [];
  return createIndex(
    data,
    startLoop,
    overallMaxDecimals,
    tokenCount,
    unburnedSupply,
    curPrice,
    ethPrice
  );
}
