import { numberWithCommas } from '../utils/dataUtil';
import { roundFloat, roundFloatToString } from '../utils/mathUtil';
import ToolTip from './tooltip';
import styled from 'styled-components';
import React, { useState } from 'react';
import contracts from '../utils/contracts';
import { useCancellablePromise } from '../helpers/useCancellablePromise';
import { loadContractSupply } from '../utils/web3Util';

const HeaderStyles = styled.div`
  /* border: 3px solid black; */
  /* border-radius: 5px; */
  #scroll-container {
    overflow: hidden;
    width: 700px;
    font-size: 0.9rem;
  }
  text-align: center;

  #scroll-text {
    /* animation properties */
    color: white;
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);

    -moz-animation: my-animation 30s linear infinite;
    -webkit-animation: my-animation 30s linear infinite;
    animation: my-animation 30s linear infinite;
  }

  /* for Firefox */
  @-moz-keyframes my-animation {
    from {
      -moz-transform: translateX(100%);
    }
    to {
      -moz-transform: translateX(-100%);
    }
  }

  /* for Chrome */
  @-webkit-keyframes my-animation {
    from {
      -webkit-transform: translateX(100%);
    }
    to {
      -webkit-transform: translateX(-100%);
    }
  }

  @keyframes my-animation {
    from {
      -moz-transform: translateX(100%);
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
    to {
      -moz-transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
  }

  img.img-eth {
    vertical-align: middle;
    width: 15px;
    height: 15px;
    fill: white;
    filter: brightness(0) invert(1);
  }
  label {
    color: white;
  }
  div.headerInfo {
    display: inline-flex;
    flex-direction: row;
    /* justify-content: center; */
    justify-content: space-evenly;
    align-items: center;
    padding: 15px 0.5rem 45px 0.5rem;
    line-height: 17px;
    width: 700px;
    flex-wrap: wrap;

    flex-direction: column;
    padding-bottom: 20px;
    padding-top: unset;
    /* width: 450px; */
    width: 100%;
  }

  /* div.header-item {
    text-align: center;
    height: 50px;
    width: 50%;
  } */

  div.header-item {
    padding-top: 10px;
    height: unset;
    width: 100%;
  }
  /* div.header-item-supply {
    order: 3;
  } */
  div.header-item-price {
    order: 1;
  }
  /* div.header-item-marketcap {
    order: 2;
  } */
  div.header-item-daily-l1 {
    order: 2;
  }
  div.header-item-daily-l2 {
    order: 3;
  }
  .header {
    color: white;
    font-size: 1rem;
  }
  .content {
    color: white;
    font-size: 0.9rem;
  }

  @media only screen and (max-width: 700px) {
    div.headerInfo {
      flex-direction: column;
      padding-bottom: 20px;
      padding-top: unset;
      /* width: 450px; */
      width: 100%;
    }

    div.header-item {
      padding-top: 10px;
      height: unset;
      width: 100%;
    }
    /* div.header-item-supply {
      order: 2;
    }
    div.header-item-price {
      order: 1;
    }
    div.header-item-marketcap {
      order: 3;
    }
    div.header-item-daily {
      order: 4;
    } */

    #scroll-container {
      overflow: hidden;
      width: 80%;
      text-align: center;
    }

    #scroll-text {
      -moz-animation: my-animation 15s linear infinite;
      -webkit-animation: my-animation 15s linear infinite;
      animation: my-animation 15s linear infinite;
    }

    .content {
      font-size: 0.8rem;
    }
  }
`;

export default function HeaderInfo(props) {
  const [supplyInfo, setSupplyInfo] = useState({});

  const results = {
    supplyValue: '-',
    percentSupply: '-',
    marketCap: '-',

    dailyBuyTxns: '-',
    dailySellTxns: '-',
    dailyTxns: '-',
    dailyBuyVolumeUSD: '-',
    dailySellVolumeUSD: '-',
    dailyVolumeUSD: '-',

    ethToEmax: '-',
    ethPriceFormatted: '-',
    priceFormatted: '-',
    oneBillionEmax: '-',
    marketCapInfo:
      '(L2 contract supply * L2 price) + (L1 contract supply * L1 price)',
    supplyInfo: "source: contract 'totalSupply' function",
    priceInfo: '-',

    usdPriceL1: '-',
    usdPriceL2: '-',

    l1Supply: '-',
    l2Supply: '-',
    totalSupply: '',

    utcBuyTxnsL1: '-',
    utcBuyVolL1: '-',
    utcSellTxnsL1: '-',
    utcSellVolL1: '-',
    utcTxnsL1: '-',
    utcVolL1: '-',

    utcBuyTxnsL2: '-',
    utcBuyVolL2: '-',
    utcSellTxnsL2: '-',
    utcSellVolL2: '-',
    utcTxnsL2: '-',
    utcVolL2: '-',
  };

  const tooltipL1 = `Based on UTC day data

  contract     ${contracts.contractL1}
  
  pair        ${contracts.pairL1}`;

  const tooltipL2 = `Based on UTC day data

  contract     ${contracts.contractL2}

  pair        ${contracts.pairL2}`;

  const { cancellablePromise } = useCancellablePromise();
  let contractSupplyInterval = React.useRef(false);

  const loadContractSupplyData = async () => {
    try {
      const data = await cancellablePromise(loadContractSupply());
      setSupplyInfo(data);
      console.log(data);
    } catch (e) {
      if (e.isCanceled) {
        console.log('Promise was canceled');
      } else {
        console.log(e);
      }
    }
  };

  React.useEffect(() => {
    loadContractSupplyData();
    contractSupplyInterval.current = setInterval(loadContractSupplyData, 10000);

    return function cleanup() {
      clearInterval(contractSupplyInterval.current);
    };
  }, []);

  if (supplyInfo.loaded && props.priceData.tokens) {
    const emaxTokenL1 = props.priceData.tokens[contracts.contractL1];
    const emaxTokenL2 = props.priceData.tokens[contracts.contractL2];

    const emaxTxnsL1 = props.priceData?.txns[contracts.pairL1]?.dailyUtc || {};
    const emaxTxnsL2 = props.priceData?.txns[contracts.pairL2]?.dailyUtc || {};

    results.l1Supply = numberWithCommas(roundFloat(supplyInfo.ethSupply));
    results.l2Supply = numberWithCommas(roundFloat(supplyInfo.arbSupply));
    results.totalSupply = numberWithCommas(
      parseInt(supplyInfo.ethSupply, 10) + parseInt(supplyInfo.arbSupply, 10)
    );

    results.percentSupply = (
      ((parseInt(supplyInfo.ethSupply, 10) +
        parseInt(supplyInfo.arbSupply, 10)) /
        2000000000000000) *
      100
    ).toFixed(2);

    results.usdPriceL1 = roundFloatToString(emaxTokenL1.usdPrice, 10);
    results.usdPriceL2 = roundFloatToString(emaxTokenL2.usdPrice, 10);

    results.marketCap = numberWithCommas(
      roundFloat(
        supplyInfo.ethSupply * emaxTokenL1.usdPrice +
          supplyInfo.arbSupply * emaxTokenL2.usdPrice
      )
    );

    results.utcBuyTxnsL1 = numberWithCommas(emaxTxnsL1.buyTransactions || 0);
    results.utcBuyVolL1 = numberWithCommas(
      roundFloat(emaxTxnsL1.buyVolume || 0)
    );
    results.utcSellTxnsL1 = numberWithCommas(emaxTxnsL1.sellTransactions || 0);
    results.utcSellVolL1 = numberWithCommas(
      roundFloat(emaxTxnsL1.sellVolume || 0)
    );
    results.utcTxnsL1 = numberWithCommas(emaxTxnsL1.totalTransactions || 0);
    results.utcVolL1 = numberWithCommas(
      roundFloat(emaxTxnsL1.buyVolume || 0) +
        roundFloat(emaxTxnsL1.sellVolume || 0)
    );

    results.utcBuyTxnsL2 = numberWithCommas(emaxTxnsL2.buyTransactions || 0);
    results.utcBuyVolL2 = numberWithCommas(
      roundFloat(emaxTxnsL2.buyVolume || 0)
    );
    results.utcSellTxnsL2 = numberWithCommas(emaxTxnsL2.sellTransactions || 0);
    results.utcSellVolL2 = numberWithCommas(
      roundFloat(emaxTxnsL2.sellVolume || 0)
    );
    results.utcTxnsL2 = numberWithCommas(emaxTxnsL2.totalTransactions || 0);
    results.utcVolL2 = numberWithCommas(
      roundFloat(emaxTxnsL2.buyVolume) + roundFloat(emaxTxnsL2.sellVolume)
    );

    // totalTransactions": 20,
    //     "totalVolume": 38414.81467278669,
    //     "buyTransactions": 14,
    //     "sellTransactions": 6,
    //     "v": 35034.23532142353,
    //     "sellVolume": 3380.5793513631575

    // results.supplyValue = numberWithCommas(
    //   Number(emax.unburnedSupply).toString().replace(/\D/g, '')
    // );

    // results.percentSupply = roundFloatToString(
    //   (emax.unburnedSupply / emax.totalSupply) * 100,
    //   2
    // );

    // results.marketCap = numberWithCommas(
    //   roundFloat(emax.unburnedSupply * roundFloat(emax.price, 10))
    // );

    // results.dailyBuyTxns = numberWithCommas(emax.pairDayDatas.dailyBuyTxns);
    // results.dailySellTxns = numberWithCommas(emax.pairDayDatas.dailySellTxns);
    // results.dailyTxns = numberWithCommas(emax.pairDayDatas.dailyTxns);

    // results.dailyBuyVolumeUSD = numberWithCommas(
    //   roundFloat(emax.pairDayDatas.dailyBuyVolumeUSD)
    // );
    // results.dailySellVolumeUSD = numberWithCommas(
    //   roundFloat(emax.pairDayDatas.dailySellVolumeUSD)
    // );

    // results.dailyVolumeUSD = numberWithCommas(
    //   roundFloat(emax.pairDayDatas.dailyBuyVolumeUSD) +
    //     roundFloat(emax.pairDayDatas.dailySellVolumeUSD)
    // );

    // results.ethToEmax = numberWithCommas(
    //   roundFloat(roundFloat(eth.price, 2) / roundFloat(emax.price, 10))
    // );

    // results.ethPriceFormatted = numberWithCommas(roundFloat(eth.price, 2));
    // results.priceFormatted = roundFloatToString(emax.price, 10);
    // results.oneBillionEmax = roundFloatToString(
    //   roundFloat(emax.price, 10) * 1000000000,
    //   2
    // );

    // results.supplyInfo = `Calculated by subtracting the tokens in the burn wallet (${numberWithCommas(
    //   emax.burnedSupply
    // )}) from the total supply`;

    // results.priceInfo = `1 Eth = $${results.ethPriceFormatted}

    // 1 Eth = ${results.ethToEmax} eMax

    // 1 Billion eMax = $${results.oneBillionEmax}`;
  }

  return (
    <HeaderStyles>
      <div className="headerInfo">
        {/* <div className="header-item header-item-price">
          <div>
            <b className="header">price</b>
            <ToolTip
              image="info-circle-svgrepo-com.svg"
              text={results.priceInfo}
            />
          </div>
          <div className="headerContent">
            <i className="content mono">(L1) {'  $' + results.usdPriceL1}</i>
          </div>
          <div className="headerContent">
            <i className="content mono">(L2) {'  $' + results.usdPriceL2}</i>
          </div>
        </div> */}

        <div className="header-item header-item-supply">
          <div>
            <b className="header">supply</b>
            <ToolTip
              image="info-circle-svgrepo-com.svg"
              text={results.supplyInfo}
            />
          </div>
          <div className="headerContent">
            <i className="content">
              {results.totalSupply + '  (' + results.percentSupply + '%)'}
            </i>{' '}
          </div>
        </div>

        <div className="header-item header-item-supply">
          <div>
            <b className="header">marketcap</b>
            <ToolTip
              image="info-circle-svgrepo-com.svg"
              text={results.marketCapInfo}
            />
          </div>
          <div className="headerContent">
            <i className="content">${results.marketCap}</i>{' '}
          </div>
        </div>

        <div className="header-item header-item-daily-l1">
          <div>
            <b className="header">layer 1</b>
            <ToolTip image="info-circle-svgrepo-com.svg" text={tooltipL1} />
          </div>
          <div className="headerContent">
            <i className="content">{'$' + results.usdPriceL1}</i>
          </div>

          <div className="headerContent">
            <i className="content">{results.l1Supply + ' tokens'}</i>
          </div>
          <div>
            <i className="content">
              {'buy $' +
                results.utcBuyVolL1 +
                ' + sell $' +
                results.utcSellVolL1 +
                ' = $' +
                results.utcVolL1}
            </i>
          </div>
          <div className="headerContent">
            <i className="content">
              {'(buy ' +
                results.utcBuyTxnsL1 +
                ' + sell ' +
                results.utcSellTxnsL1 +
                ' = ' +
                results.utcTxnsL1 +
                ' txns)'}
            </i>
          </div>
        </div>

        <div className="header-item header-item-daily-l2">
          <div>
            <b className="header">layer 2</b>
            <ToolTip image="info-circle-svgrepo-com.svg" text={tooltipL2} />
          </div>
          <div className="headerContent">
            <i className="content">{'$' + results.usdPriceL2}</i>
          </div>
          <div className="headerContent">
            <i className="content">{results.l2Supply + ' tokens'}</i>
          </div>
          <div>
            <i className="content">
              {'buy $' +
                results.utcBuyVolL2 +
                ' + sell $' +
                results.utcSellVolL2 +
                ' = $' +
                results.utcVolL2}
            </i>
          </div>
          <div className="headerContent">
            <i className="content">
              {'(buy ' +
                results.utcBuyTxnsL2 +
                ' + sell ' +
                results.utcSellTxnsL2 +
                ' = ' +
                results.utcTxnsL2 +
                ' txns)'}
            </i>
          </div>
        </div>

        {/* <div className="header-item header-item-marketcap">
          <div>
            <b className="header">market cap</b>
            <ToolTip
              image="info-circle-svgrepo-com.svg"
              text={results.marketCapInfo}
            />
          </div>
          <div>
            <i className="content">{'  $' + results.marketCap}</i>
          </div>
        </div> */}
      </div>
    </HeaderStyles>
  );
}
