import styled from 'styled-components';
import React from 'react';
import { numberWithCommas } from '../utils/dataUtil';
import { roundFloat, roundFloatToString } from '../utils/mathUtil';
import contracts from '../utils/contracts';

const SummaryStyles = styled.div`
  td {
    padding: 10px;
  }
  td.heading {
    text-decoration: underline;
  }

  table {
    border-spacing: 0;
    font-size: 16px;
    color: white;
    padding-bottom: 15px;

    margin-left: auto;
    margin-right: auto;
  }

  tr {
    line-height: 8px;
  }

  img.eth-logo {
    vertical-align: middle;
    width: 15px;
    height: 15px;
  }
`;

export default function MillionLabel(props) {
  if (
    props.noEmax &&
    props.noEmax > 0 &&
    props.priceData &&
    props.priceData.tokens
  ) {
    const priceL2 = props.priceData.tokens[contracts.contractL2].usdPrice;
    const ethPriceL2 = props.priceData.tokens[contracts.contractL2].ethPrice;

    const millionDollarPrice = roundFloatToString(1000000 / props.noEmax, 10);
    const currentValue = roundFloatToString(
      props.noEmax * roundFloat(priceL2, 10),
      2
    );
    const ethValue = roundFloatToString(
      (props.noEmax * priceL2) / ethPriceL2,
      3
    );

    return (
      <SummaryStyles>
        <div>
          <table>
            <tbody>
              <tr>
                <td>
                  <b>$ Value (L2)</b>
                </td>
                <td>
                  ${numberWithCommas(currentValue)}
                  {/* /{' '}
                  <img
                    className="eth-logo"
                    src="ethereum_logo.svg"
                    alt="eth"
                  ></img>{' '}
                  {ethValue} */}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Eth Value (L2)</b>
                </td>
                <td>{numberWithCommas(ethValue)}</td>
              </tr>
              <tr>
                <td>
                  <b>$ Million Price</b>
                </td>
                <td>${millionDollarPrice}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </SummaryStyles>
    );
  }
  return <div />;
}
