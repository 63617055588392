import styled from 'styled-components';
import React from 'react';
import { createData } from '../utils/dataUtil';
import Table from './table';
import contracts from '../utils/contracts';

const RadioButtonStyles = styled.div`
  .wrapper {
    display: inline-flex;
    background: inherit;
    height: 65px;
    width: 280px;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 5px;
    border-color: white;
    padding: 30px 15px 0px 15px;
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.2);
    flex-direction: row;

    display: none;

    margin-left: auto;
    margin-right: auto;
    margin: auto;
  }
  .wrapper .option {
    /* background: rgb(7, 17, 35); */
    /* background: #fff; */
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 10px;
    border-radius: 5px;
    cursor: pointer;
    padding: 0 10px;
    border: 2px solid lightgrey;
    transition: all 0.3s ease;
  }
  .wrapper .option .dot {
    opacity: 0;
    position: fixed;
    width: 0;

    /* height: 20px;
    width: 20px;
    background: #d9d9d9;
    border-radius: 50%;
    position: relative; */
  }
  .wrapper .option .dot::before {
    position: absolute;
    content: '';
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    background: #0069d9;
    border-radius: 50%;
    opacity: 0;
    transform: scale(1.5);
    transition: all 0.3s ease;
  }
  input[type='radio'] {
    display: none;
  }

  ::selection {
    color: white;
    background-color: black;
  }
  ::-moz-selection {
    color: white;
    background-color: black;
  }
  mark {
    color: white;
    background-color: black;
  }
  [type='checkbox']:checked,
  [type='checkbox']:not(:checked),
  [type='radio']:checked,
  [type='radio']:not(:checked) {
    position: absolute;
    left: -9999px;
    width: 0;
    height: 0;
    visibility: hidden;
  }

  .checkbox-tools + label {
    border-color: rgb(236, 29, 50);
    border-width: 1px;
    border-style: solid;
  }

  .checkbox-tools:checked + label,
  .checkbox-tools:not(:checked) + label {
    position: relative;
    display: inline-block;
    padding: 7px;
    width: 70px;
    font-size: 12px;
    line-height: 10px;
    font-weight: bold;
    letter-spacing: 1px;
    margin: 0 auto;
    margin-left: 1px;
    margin-right: 1px;
    margin-bottom: 0px;
    text-align: center;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    text-transform: uppercase;
    color: white;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
  }
  .checkbox-tools:not(:checked) + label {
    background-color: transparent;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
  .checkbox-tools:checked + label {
    background-color: rgb(236, 29, 50);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  .checkbox-tools:not(:checked) + label:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  .checkbox-tools:checked + label::before,
  .checkbox-tools:not(:checked) + label::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    /* background-image: linear-gradient(298deg, red, yellow); */
    z-index: -1;
  }
  .checkbox-tools:checked + label .uil,
  .checkbox-tools:not(:checked) + label .uil {
    font-size: 24px;
    line-height: 24px;
    display: block;
    padding-bottom: 10px;
  }

  .for-checkbox-tools {
    font-style: italic;
  }

  @media only screen and (max-width: 700px) {
    div.wrapper {
      display: flex;
    }
  }
`;

export default function Results(props) {
  const [col, setCol] = React.useState('fiatValue');

  function handleRadio(event) {
    setCol(event.target.id);
  }

  if (
    props.noEmax &&
    props.noEmax > 0 &&
    props.priceData &&
    props.priceData.tokens
  ) {
    const priceL2 = props.priceData.tokens[contracts.contractL2].usdPrice;
    const ethPriceL2 = props.priceData.tokens[contracts.contractL2].ethPrice;
    const totalSupply = props.priceData.supply.l1 + props.priceData.supply.l2;
    const data = createData(priceL2, props.noEmax, totalSupply, ethPriceL2);

    const columns = [
      {
        Header: 'Price',
        accessor: 'price',
        show: true,
      },
      {
        Header: '$ Value (L2)',
        accessor: 'value',
        show: col === 'fiatValue',
      },
      {
        Header: 'Eth Value (L2)',
        accessor: 'ethValue',
        show: col === 'ethValue',
        prefix: <img className="img-eth" src="ethereum_logo.svg" alt="i" />,
      },
      {
        Header: 'Market Cap',
        accessor: 'marketCap',
        show: col === 'marketCap',
        tooltip:
          'Based on L2 Pricing - This will vary from the market cap at the top of the page',
      },
      {
        Header: 'Multiplier',
        accessor: 'multiplier',
        show: col === 'multiplier',
      },
      {
        Header: "0's",
        accessor: 'zeros',
        show: true,
      },
    ];

    return (
      <>
        <RadioButtonStyles>
          <div className="wrapper" onChange={handleRadio}>
            <input
              className="checkbox-tools"
              type="radio"
              name="select"
              id="fiatValue"
              defaultChecked
            />
            <label htmlFor="fiatValue" className="for-checkbox-tools option-1">
              <div className="dot" />
              <span>
                $ <br />
                Value
              </span>
            </label>
            <input
              className="checkbox-tools"
              type="radio"
              name="select"
              id="ethValue"
            />
            <label htmlFor="ethValue" className="for-checkbox-tools option-2">
              <div className="dot" />
              <span>
                Eth
                <br />
                Value
              </span>
            </label>
            <input
              className="checkbox-tools"
              type="radio"
              name="select"
              id="marketCap"
            />
            <label htmlFor="marketCap" className="for-checkbox-tools option-3">
              <div className="dot" />
              <span>
                Market <br /> Cap
              </span>
            </label>
          </div>
        </RadioButtonStyles>

        <Table columns={columns} data={data} />
      </>
    );
  }
  return <div />;
}
