import styled from 'styled-components';
import React from 'react';
import { formatMonth, padNumber } from '../utils/dateUtils';

const Style = styled.div`
  div.utc-container {
    /* height: 100vh; */
    /* text-align: right; */
    width: fit-content;
    padding: 0 0 0 0.5rem;
    margin: 0;
    /* display: flex;
    flex-direction: column; */
    label {
      /* display: block; */
      font-size: 0.7rem;
      color: white;
    }
  }
`;

export default function Clock() {
  const [date, setDate] = React.useState('-');
  const [time, setTime] = React.useState('-');

  setInterval(() => {
    const now = new Date();

    const date = now.getUTCDate();
    const month = formatMonth(now.getUTCMonth() + 1);
    const year = now.getUTCFullYear();

    const hour = padNumber(now.getUTCHours());
    const min = padNumber(now.getUTCMinutes());
    const sec = padNumber(now.getUTCSeconds());

    setDate(`${date} ${month} ${year}`);
    setTime(`${hour}:${min}:${sec} GMT`);
  }, 1000);

  return (
    <Style>
      <div className="utc-container">
        <label className="utc-date mono">{date + ' ' + time}</label>
        {/* <label className="utc-time mono">{time}</label> */}
      </div>
    </Style>
  );
}
