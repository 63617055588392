import React from 'react';
import styled from 'styled-components';
import MillionLabel from '../millionLabel';
import Results from '../results';
import Slider from '../slider';
import { toNumbersOnly, numberWithCommas } from '../../utils/dataUtil';
import { setCookie, getCookie } from '../../utils/cookieUtil';
import StyledInput from '../inputs/styledInput';

const Styles = styled.div`
  div.input-container {
    margin-left: auto;
    margin-right: auto;
  }
  .display-block {
    display: block;
  }
  .display-none {
    display: none;
  }
  .emax-input {
    font-weight: bold;
    font-size: 22px;
  }
`;

export default function ValueCalculator(props) {
  // const showHideClassName = props.show ? 'display-block' : 'display-none';
  const showHideClassName = 'display-block';

  const [tokenCount, setTokenCount] = React.useState(
    localStorage.getItem('tokens') ?? 0
  );

  // function handleCheck(event) {
  //   localStorage.setItem('tokens', tokenCount);
  //   localStorage.setItem('save-tokens', event.target.checked);
  // }

  function handleChange(evt) {
    const { value } = evt.target;
    const valueWithNumbersOnly = toNumbersOnly(value) || '0';
    const valueLessThanMax =
      valueWithNumbersOnly.length <= 15
        ? valueWithNumbersOnly
        : valueWithNumbersOnly.substring(0, 15);
    const intValue = value ? parseInt(valueLessThanMax) : 0;
    localStorage.setItem('tokens', intValue);
    setTokenCount(intValue);
  }

  return (
    <>
      <Styles>
        <div className={showHideClassName}>
          <div className="input-container">
            <StyledInput
              label="tokens #"
              value={tokenCount > 0 ? numberWithCommas(tokenCount) : ''}
              onChange={handleChange}
              height={50}
            />
            {/* <Slider
              defaultChecked={localStorage.getItem('save-tokens') == 'true'}
              onChange={handleCheck}
              label="Save"
            /> */}
          </div>

          {/* <MillionLabel noEmax={tokenCount} priceData={props.priceData} /> */}

          <Results noEmax={tokenCount} priceData={props.priceData} />
        </div>
      </Styles>
    </>
  );
}
